@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@600&family=Merriweather&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bannerIcon {
    @apply w-16 h-16 bg-black bg-opacity-45 text-gray-200 text-3xl inline-flex items-center justify-center rounded-md shadow-2xl hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300;
  }
  .resumeLi {
    @apply w-full h-20 bg-black bg-opacity-25 text-xl text-gray-300 flex justify-center cursor-pointer hover:bg-opacity-40 duration-300 items-center border-[1px] rounded-none;
  }
  .bgOpacity {
    @apply bg-black bg-opacity-40;
  }
  .contactInput {
    @apply w-full h-12 rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300;
  }
  .contactTextArea {
    @apply w-full rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 py-2 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300 resize-none;
  }
}

.active {
  color: #ff014f;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.video{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  /* z-index: '-50%'; */
  position: fixed;
  opacity: 0.4;
}

/* ,app{
  background: url('./assets/video.mp4');
} */

